import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  SocialMedia,
  Content,
  CallToActionBanner,
  InternalLink,
  Call,
  PaginatedGrid,
  componentIterator,
  Image,
  Review,
} from "@bluefin/components";
import { Grid, Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"home-hero"}>
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
                background={true}
                forceChildren={true}
              >
                <div className={"welcome-content-container"}>
                  <Grid className={"welcome-content"} stackable={true}>
                    <Grid.Row verticalAlign={"middle"}>
                      <Grid.Column
                        width={8}
                        textAlign={"left"}
                        className={"title-column"}
                      >
                        <Header as={"h2"}>WELCOME</Header>
                      </Grid.Column>
                      <Grid.Column
                        width={8}
                        textAlign={"right"}
                        className={"social-media-column"}
                      >
                        <SocialMedia
                          socialMediaValues={fishermanBusiness.socialMedia}
                          buttonType={"circle"}
                          centerHeader={false}
                          colors={"white"}
                          groupButtons={false}
                          groupVertically={false}
                          inverted={true}
                          labelPosition={"free"}
                          showLabels={false}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Column width={16} className={"welcome-copy"}>
                      <Content stackable={true} textAlign={"left"}>
                        <Content.Markup
                          width={16}
                          isMarkdown={true}
                          content={fishermanBusiness.aboutMarkdown}
                        />
                      </Content>
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <CallToActionBanner stackable={true}>
                        <CallToActionBanner.CTA>
                          <Button
                            primary={true}
                            size={"large"}
                            to={"/services/"}
                            as={InternalLink}
                          >
                            View Services
                          </Button>
                        </CallToActionBanner.CTA>
                        <CallToActionBanner.CTA>
                          <Call
                            phoneNumber={
                              fishermanBusiness.primaryLocation.phoneNumber
                            }
                            as={"button"}
                            buttonColor={"primary"}
                          />
                        </CallToActionBanner.CTA>
                      </CallToActionBanner>
                    </Grid.Column>
                  </Grid>
                </div>
              </Background.Image>
            </Background>
          }
          subfooter={false}
          className={"home-page"}
        >
          <Grid
            columns={2}
            stackable={true}
            doubling={true}
            className={"home-content"}
          >
            <Grid.Column>
              <PaginatedGrid
                rows={3}
                className={"image-gallery"}
                mobile={{ columns: 2, rows: 4, stackable: false }}
                items={componentIterator({
                  iterator: getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "ImageCollection",
                  }),
                  component: (
                    <Image
                      background={true}
                      style={{ paddingTop: "100%", height: "100%" }}
                    />
                  ),
                  propMap: { src: "__all__" },
                })}
              />
            </Grid.Column>
            <Grid.Column>
              <PaginatedGrid
                rows={2}
                columns={2}
                className={"reviews"}
                tight={true}
                items={componentIterator({
                  iterator: fishermanBusiness.reviews,
                  component: <Review as={"horizontalItem"} charLimit={200} />,
                  propMap: { author: "author", text: "text" },
                })}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      socialMedia {
        link
        type
      }
      aboutMarkdown
      primaryLocation {
        phoneNumber
      }
      reviews {
        author
        text
        link
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
